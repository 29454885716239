import React from 'react'

const SupportiveLiving = () => {
  return (
<section className="supportive-living-services" id='about'>
    <div className='supportive-living-services-wrapper'>
      <div className="content-wrapper">
        <div className="text-content">
          <p>Discover Our Comprehensive Range of Supportive Living Services Tailored for You</p>
        </div>
        <div className="description-content">
          <p>At Mtima Supported Living Services, we offer a variety of personalised care solutions designed to enhance your quality of life. Our dedicated team is here to support you in the comfort of your own home, ensuring your needs are met with compassion and professionalism. Explore our services to find the perfect fit for your lifestyle.</p>
        </div>
      </div>
      <div className="services-wrapper">
        <div className="service-card">
          <div className="service-icon">🏠</div>
          <h3>Personal Care</h3>
          <p>Assistance with daily living activities, ensuring comfort and independence.</p>
        </div>
        <div className="service-card">
          <div className="service-icon">🛌</div>
          <h3>Live-In Care</h3>
          <p>Round-the-clock support and companionship in the comfort of your home.</p>
        </div>
        <div className="service-card">
          <div className="service-icon">👥</div>
          <h3>Supported Living</h3>
          <p>Empowering individuals to live independently with tailored support.</p>
        </div>
        <div className="service-card">
          <div className="service-icon">🔄</div>
          <h3>Respite Care</h3>
          <p>Short-term relief for caregivers, ensuring continuous quality care.</p>
        </div>
      </div>
      </div>
    </section>
  )
}

export default SupportiveLiving
