import React from "react";

const Button = ({buttonText, hasBackground, phoneNumber, email}) => {

  const getHref = () => {
    if (phoneNumber) {
      return `tel:${phoneNumber}`; // Call the phone number
    } else if (email) {
      return `mailto:${email}`; // Open email client
    } else {
      return "#"; // Do nothing or provide a default action
    }
  };
  return (
    <div className="contact-button">
      <a href={getHref()}>
      <button className={hasBackground ? "btn-contact btn-bg" : "btn-contact"}>{buttonText}</button>
      </a>
    </div>
  );
};

export default Button;
