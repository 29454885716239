import React from 'react'
import Hero from '../components/Hero'
import SanctuaryOfCare from '../components/SanctuaryOfCare'
import SupportiveLiving from '../components/SupportiveLiving'
import DiscoverBenefits from '../components/DiscoverBenefits'
import Consultation from '../components/Consultation'

const HomePage = () => {
  return (
    <div>
      <Hero/>
      <SanctuaryOfCare/>
      <SupportiveLiving/>
      <DiscoverBenefits/>
      <Consultation/>
    </div>
  )
}

export default HomePage
