import React, { useState, useEffect } from "react";
import logo from "../../assets/mita.png";
import Button from "./Button";

const Navbar = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  return (


    <header>
      <nav className="navbar">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="MTIMA Logo" />
          </a>
        </div>
        {isMobile ? (
          <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        ) : (
          <>
            <ul className="nav-links">
              <li><a href="#homecare">Homecare</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#consultation">Consultations</a></li>
            </ul>
            <Button buttonText="Contact Us" onClick={toggleMenu} email="info@mtimasupportedliving.com" />
          </>
        )}
      </nav>
      {isMobile && (
        <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
          <ul className="mobile-nav-links">
            <li><a href="#homecare" onClick={toggleMenu}>Homecare</a></li>
            <li><a href="#about" onClick={toggleMenu}>About</a></li>
            <li><a href="#services" onClick={toggleMenu}>Services</a></li>
            <li><a href="#consultation" onClick={toggleMenu}>Consultation</a></li>
          </ul>
          <Button buttonText="Contact Us" onClick={toggleMenu} email="info@mtimasupportedliving.com" />
        </div>
      )}
      <div className="liner"></div>
    </header>
  );
};

export default Navbar;
