import React from 'react'
import disover from '.././assets/discover.png'

const DiscoverBenefits = () => {
  return (
<section className="mtima-benefits" id="services">
    <div className='mtima-benefits-wrapper'>
      <div className="image-container">
        <img src={disover} alt="Caregiver assisting elderly person" className="benefits-image" loading='lazy' />
      </div>
      <div className="content-container">
        <h2 className="benefits-title">Discover the Exceptional Benefits of Choosing Mtima Supported Living Services</h2>
        <p className="benefits-description">
          At Mtima, we pride ourselves on delivering personalised care that meets your unique needs. Our experienced team is dedicated to ensuring your comfort and well-being every step of the way.
        </p>
        <div className="benefits-features">
          <div className="feature">
            <div className="feature-icon">💎</div>
            <h3 className="feature-title">Trusted Experience</h3>
            <p className="feature-description">Over 20 years experience in nursing and healthcare.</p>
          </div>
          <div className="feature">
            <div className="feature-icon">🏅</div>
            <h3 className="feature-title">CQC Registered</h3>
            <p className="feature-description">Our services meet the highest standards of quality and safety as regulated by CQC.</p>
          </div>
        </div>
      </div>
      </div>
    </section>
  )
}

export default DiscoverBenefits
