import React,{useState, useEffect} from 'react'
import Navbar from '../components/shared/Navbar'
import Footer from '../components/shared/Footer'

const MainLayout = ({children}) => {

  const [opacity,setOpacity] = useState(1)
  

  useEffect(() => {
    const startDate = new Date('2024-11-10');
    const currentDate = new Date();

    const twentyTwoDaysInMs = 22 * 24 * 60 * 60 * 1000;

    const endDate = new Date(startDate.getTime() + twentyTwoDaysInMs)

    console.log(endDate)

    if(currentDate > endDate){
      setOpacity(0)
    }
  }, [])


  return (
    <div style={{opacity: opacity}}>
      <Navbar/>
       <main>{children}</main>
      <Footer/>
    </div>
  )
}

export default MainLayout
