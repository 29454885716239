import React from "react";
import logo from "../../assets/logo.png";
import linkedin from "../../assets/Linkedin.png"
import facebook from "../../assets/facebook.png"
import twitter from "../../assets/Twitter.png"
import brand1 from "../../assets/brand1.png";
import brand2 from "../../assets/brand2.png";
import brand3 from "../../assets/brand3.png";
import brand4 from "../../assets/brand4.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-content">
          <div className="footer-left">
            <a href="/">
              <img src={logo} alt="MTIMA Logo" className="logo" />
            </a>
            <p className="contact-info">
              {" "}
              <a href="tel: 07903601655"> 07903601655</a> |{" "}
              <a href="tel:07853024921">07853024921</a> |{" "}
              <a href="tel:01376408047"> 01376408047</a>
            </p>
            <p className="contact-info">
              {" "}
              <a href="mailto:info@mtimasupportedliving.com ">
                info@mtimasupportedliving.com
              </a>
            </p>
          </div>
          <div className="footer-center">
            <div className="quick-links">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <a href="/">Services</a>
                </li>
                <li>
                  <a href="/">Contact</a>
                </li>
                <li>
                  <a href="/">About Us</a>
                </li>
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="certificates">
              <img src={brand1} alt="Certificate 1" className="cert-img" />
              <img src={brand2} alt="Certificate 2" className="cert-img" />
              <img src={brand3} alt="Certificate 3" className="cert-img" />
              <img src={brand4} alt="Certificate 3" className="cert-img" />
            </div>
          </div>
          <div className="footer-right">
            <div className="subscribe">
              <h3>Subscribe</h3>
              <form className="subscribe-form">
                <input type="email" placeholder="Get product updates" />
                <button type="submit">→</button>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="social-icons">
            <a href="/" className="social-icon linkedin">
              <img src={linkedin} alt="LinkedIn Icon" />
            </a>
            <a href="/" className="social-icon facebook">
            <img src={facebook} alt="Facebook Icon" />
            </a>
            <a href="/" className="social-icon twitter">
            <img src={twitter} alt="Twitter Icon" />
            </a>
          </div>
          <p>
            A product of{" "}
            <img src={logo} alt="Product Logo" className="product-logo" />
          </p>
          <p className="copyright">© 2024 Mtima Supported Living Services</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
