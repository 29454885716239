import React from "react";
import sanctuary from "../assets/sanctuary.png";
import Button from "./shared/Button";

const SanctuaryOfCare = () => {
  return (
    <section className="comfort-section" id="homecare">
      <div className="comfort-section-wrapper">
        <div className="content-wrapper">
          <p className="title">Comfort</p>
          <p className="subtitle">Your Home, Your Sanctuary of Care</p>
          <p className="description">
            Feeling comfortable and secure in your own home is essential for
            your well-being. At Mtima, we prioritise your comfort and care,
            ensuring you receive the support you need.
          </p>
          <div className="features-wrapper">
            <div className="feature">
              <div className="feature-icon">♥️</div>
              <h4 className="feature-title">Personalised Care</h4>
              <p className="feature-description">
                Tailored services that respect your dignity and independence.
              </p>
            </div>
            <div className="feature">
              <div className="feature-icon">👐</div>
              <h4 className="feature-title">Peace of Mind</h4>
              <p className="feature-description">
                Experience compassionate support in the comfort of your home.
              </p>
            </div>
          </div>
          <Button buttonText="Learn More" hasBackground={true} />
        </div>
        <div className="image-wrapper">
          <img
            src={sanctuary}
            alt="Caregiver assisting patient"
            className="comfort-image"
          />
        </div>
      </div>
    </section>
  );
};

export default SanctuaryOfCare;
