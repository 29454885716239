import React from "react";
import Button from "./shared/Button";

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <div className="hero-badge">
          <span className="badge-star">★</span>
          <span>Welcome to Mtima Supported Living Services</span>
        </div>
        <div className="content">
        <p className="heading">Caring Beyond the Ordinary</p>
        <p className="sub-text">
          Personalised care services that respect your dignity, independence,
          and individual needs.
        </p>
        </div>
        <div className="hero-buttons">
            <Button buttonText="Get In Touch" hasBackground="true" email="info@mtimasupportedliving.com" />
            <Button buttonText="Learn More"/>
        </div>
      </div>
      {/* Background SVGs */}
      <div className="hero-bg-left"></div>
      <div className="hero-bg-right"></div>
    </section>
  );
};

export default Hero;
