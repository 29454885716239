import React from 'react'
import consultaion from '.././assets/consultation.png'
import Button from './shared/Button'

const Consultation = () => {
  return (
<section className="consultation-cta" id='consultation'>
    <div className='consultation-cta-wrapper'>
      <div className="cta-content">
        <h2>Schedule Your Free Consultation</h2>
        <p>Reach out today and discover how we can support your loved ones with personalised care.</p>
        <div className="cta-buttons">
          <Button buttonText="Get In Touch" hasBackground={true}/>
          <Button buttonText="Learn More"/>
        </div>
      </div>
      <div className="cta-image">
        <img src={consultaion} alt="Nurse on phone" />
      </div>
      </div>
    </section>
  )
}

export default Consultation
